/*  Navbar dropdown styles  */

.navbar-dd-menu{
    border-radius: 0 0 40px 40px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.09);
    background-color: #ffffff;
    z-index: 10;
}

.navbar-menu-item{
    padding: 160px 50px;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
}

.navbar-menu-item p{
    z-index: 5;
}

.navbar-menu-item a{
    font-size: 21px!important;
    display: flex;
    align-items: center;
    z-index: 1;
    color: #535B65;
    text-decoration: none;
}

.navbar-menu-item:hover{
    background-color: #FAF7F3;
}
.navbar-menu-item:first-child:hover{
    border-radius: 0 0 0 40px;
}

.navbar-menu-item:last-child:hover{
    border-radius: 0 0 40px 0;
}

.navbar-menu-item:hover span{
    position: absolute;
    z-index: -1;
    padding: 22px 22.75px;
    border-radius: 20px;
    background-color: #FDD871;
    margin-left: -10px;
    margin-top: 2px;
}

.navbar-links{
    color: #535B65!important;
    font-size: 21px!important;
    line-height: 24px!important;
    text-transform: none!important;
    padding: 0 0 10px!important;
    text-decoration: none;
}

.navbar-links:hover, .navbar-links-active{
    text-decoration: none!important;
    color: #FA4A38!important;
    border-bottom: 4px solid #FA4A38;
    background-color: transparent!important;
    border-radius: 0;
    padding: 0 0 10px!important;
    filter: invert(33%) sepia(56%) saturate(1210%) hue-rotate(344deg) brightness(65%) contrast(584%);
}

.navbar-dd-popper{
    transform: translate3d(0px, 125px, 0px)!important;
    width: 100%!important;
    margin-top: 50px;
    z-index: 100;
}

.navbar-separator:nth-child(2){
    border-left: 2px solid #DDDDDD;
    border-right: 2px solid #DDDDDD;
}