.carousel .slider-wrapper{
    border-radius: 40px 6px 40px 6px!important;
    float: left;
    cursor: pointer;
}

.carousel .thumbs-wrapper{
    margin: 0!important;
}

.carousel .thumbs{
    padding: 0!important;
}

.carousel .thumb{
    width: 152px!important;
    height: auto;
    opacity: 0.5!important;
    padding: 0!important;
    border-radius: 20px;
    margin-top: 12px;
    margin-right: 20px;
    border: none!important;
}

.carousel .control-dots .dot{
    /*opacity: 1!important;*/
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover{
    width: 32px;
    border-radius: 3px;
    height: 6px;
    transition: width 550ms;
    box-shadow: none;
}
