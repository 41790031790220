.lead-filter-dropDown{
    top: 40px!important;
    left: 0!important;
    padding: 10px 0px!important;
    width:180px;
    z-index: 21!important;
    overflow: visible;
  }

  
  .rightdropdown{
    right:0px!important;
    left:auto!important;
    margin-top: 10px!important;
  }
 
  .lead-filter-dropDown hr{
  opacity: 0.76;
  border: 0.5px dashed rgba(128, 151, 177, 0.4);
   margin-top: 10px;
   margin-bottom: 10px;
  font-size: 15px;
  }
  .lead-filter-dropDown .headrow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    border: none!important;
    padding: 5px 0px !important;
    margin-left: 20px;

}


th .checkbutton{
    margin-top: 8px;
  }
  .checkbutton {
      display: flex;
      position: relative;
      padding-left: 35px;
      height: 22px;
      cursor: pointer;
      font-size: 22px;
      user-select: none;
      margin-top: 5px;
      align-items: center;
  
    }
    .checkbutton:hover{
      background-color: transparent;
    }
    
    /* Hide the browser's default checkbox */
  .checkbutton  input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    
    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      border-radius: 4px;
      /* background-color:#DADADA;; */
      border:2px solid #DADADA;
     
    }
    .lead-filter-text{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #303E67;
    }
    .lead-filter-title{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #303E67;
      margin-left: 20px;
      margin-top: 20px;
      
    }
    .lead-filter-dropDown{
      top: 35px!important;
      left: 0!important;
      padding: 10px 0px!important;
      width:180px;
      z-index: 21!important;
    }
    .db{
      display:block!important;
  
    }
    .customer-filter-dropdown{
      top: 35px!important;
      left: 0!important;
      padding: 10px 0px!important;
      width:180px;
      z-index: 21!important;
      display:block!important;
    }
    .dropdown-item{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      padding: 7px !important;
    }
    /* .unstyled::-webkit-inner-spin-button,
  .unstyled::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
  }
  ::-webkit-calendar-picker-indicator { display: none; } */
  
    .rightdropdown{
      right:0px!important;
      left:auto!important;
      margin-top: 10px!important;
    }
   
    .lead-filter-dropDown hr{
    opacity: 0.76;
    border: 0.5px dashed rgba(128, 151, 177, 0.4);
     margin-top: 10px;
     margin-bottom: 10px;
    font-size: 15px;
    }
    .lead-filter-dropDown .headrow {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      border: none!important;
      padding: 5px 0px !important;
      margin-left: 20px;
  
  }