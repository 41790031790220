.html .body{
    /* background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12); */
}

.adminlogin .sectionTwo .form-input input {
    text-transform: none;
}

.sectionTwo .form-input input{
    text-transform: none;
}

.adminlogin .sectionTwo{
    text-align: center;
/* width: calc(120vw -500px); */
/* background: #2a6bb5;
 */
 /* background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12); */
box-shadow: 0px 4px 34px rgba(181, 42, 101, 0.3);
border-radius: 10px;
height: 450px;
position: absolute;
bottom: 220px  !important;
max-width: 800px;
top :15%;
left: 50%;
transform: translate(-50%);
}
.adminlogin .text-right{
    text-align: right;
}
.adminlogin .sectionTwo h4{
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    letter-spacing: 3px;
    /* text-transform: uppercase; */
    color: #F9F9F9;
    padding: 60px 0px 40px 0px;
}
.adminlogin .sectionTwo form{
    padding: 0px 70px;
}
.adminlogin .sectionTwo .form-input input{
    margin-left: 20%;
    text-align: center;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
    background: none;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    /* text-align: center; */
    letter-spacing: 3px;
    /* text-transform: uppercase; */
    width: 100%;
    margin-bottom: 20px;
    height: 60px;
    padding: 20px;
}
.adminlogin .sectionTwo .form-input input::placeholder{
    color: #fff;
}
.adminlogin .sectionTwo .form-input button{  
    margin-left: 20%; 
    background: #F7F7F7;
    border-radius: 10px;
    height: 60px;
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    /* identical to box height, or 125% */
    text-align: center;
    letter-spacing: 3px;
    /* text-transform: uppercase; */
    color: #051937;
    border: none;
}

.adminlogin .loginbtn{
    margin-left: 5%;
}